import { encodeBase64, IntegrationData } from '@wix/pricing-plans-router-utils';

export function integrationDataToAppSectionParams(integrationData: IntegrationData): URLSearchParams {
  const { planIds, navigateToPageProps, navigateToSectionProps, biOptions, verticalStatusContent, ...rest } =
    integrationData;
  return new URLSearchParams({
    appSectionParams: JSON.stringify({
      ...rest,
      planIds: planIds?.join(','),
      biOptions: biOptions && encodeBase64(biOptions),
      navigateToSectionProps: navigateToSectionProps && encodeBase64(navigateToSectionProps),
      navigateToPageProps: navigateToPageProps && window.btoa(navigateToPageProps),
      verticalStatusContent: verticalStatusContent && encodeBase64(verticalStatusContent),
    }),
  });
}
